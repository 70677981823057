<template>
  <div>
    <v-card tile outlined style="" class="login-header">
      <v-img
        src="@/assets/img/logo.png"
        class="ma-5 mb-4 ma-sm-7 mb-sm-6 ma-md-12 mb-md-11 login-logo"
      >
      </v-img>
    </v-card>
    <v-img
      src="@/assets/img/home_login_new.jpg"
      cover
      class="d-flex justify-center align-center bg-img-login"
    >
      <v-container class="container-login">
        <v-row>
          <v-col cols="12" md="7">
            <v-list-item class="pa-0 mb-4">
              <v-list-item-content>
                <h1
                  class="mb-4 text-h5 text-sm-h4 font-weight-bold white--text"
                  style="text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);"
                  v-html="$t('pages.login.page_title')"
                ></h1>
                <p
                  class="title mb-0 white--text"
                  style="text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);"
                  v-html="$t('pages.login.page_intro')"
                ></p>

              </v-list-item-content>
            </v-list-item>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  v-on="on"
                  v-html="$t('profil.login')"
                ></v-btn>
              </template>
              <v-card>
                <v-img
                  src="@/assets/img/logo.png"
                  width="150px"
                  class="mx-4 mb-3 mt-8"
                ></v-img>
                <v-card-text>
                  <v-form ref="form" @submit="login" onSubmit="return false;">
                    <v-text-field
                      v-model="username"
                      :label="$t('profil.email')"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      :label="$t('profil.password')"
                      :hint="$t('profil.password_size')"
                      counter
                      @click:append="show1 = !show1"
                    >
                  </v-text-field>
                    <v-btn
                      color="grey darken-2"
                      class="white--text mt-5 mb-4"
                      :loading="loading"
                      type="submit"
                      v-html="$t('profil.login')"
                    ></v-btn>
                    <p>
                      <a
                      href="https://admin.formation.ciafj.ch/user/register" target="_blank"
                      link
                      class="black--text"
                    >
                      Créer un compte
                    </a>
                    &middot;
                    <a
                      href="https://admin.formation.ciafj.ch/user/password" target="_blank"
                      link
                      class="black--text"
                    >
                      J'ai oublié mon mot de passe
                    </a>
                  </p>
                  </v-form>

                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/auth'

export default {
  data() {
    return {
      show1: false,
      dialog: false,
      username: localStorage.getItem('username', ''),
      password: '',
      loading: false
    }
  },
  methods: {
    login: function() {
      this.loading = true
      const { username, password } = this
      this.$store
        .dispatch(AUTH_REQUEST, { username, password })
        .then(resp => {
          this.loading = false
          if (resp) {
            this.$router.push({ name: 'home' })
          } else {
            this.$router.push({ name: 'login' })
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  },

  mounted() {
    localStorage.clear()
  }
}
</script>
<style lang="scss">
.bg-img-login .v-image__image--cover {
  // filter: blur(4px) brightness(75%);
  filter: brightness(65%);
}

@media screen and (min-width: 960px) {
  .login-logo {
    width: 300px !important;
    height:107px;
  }
}

.login-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
.bg-img-login {
  max-height: 100vh;
  height: 100vh;
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .login-logo {
    width: 250px !important;
  }
}
@media screen and (max-width: 599px) {
  .login-logo {
    width: 150px !important;
  }

  .bg-img-login {
    max-height: 100vh;
    overflow-y: scroll;
    height: 100vh;
    // position: fixed;
  }

  .container-login {
    padding-top: 80px;

    .v-list-item__content h1 {
      font-size: 22px !important;
      line-height: 28px;
    }

    .v-list-item__content p {
      font-size: 17px !important;
      line-height: 22px;
    }
  }

  .v-footer {
    position: initial;
  }
}

@media screen and (min-width: 600px) and (max-height: 850px) {
  .login-logo {
    width: 220px !important;
    margin: 20px !important;
  }

  .v-application .ma-md-12.mb-md-11 {
    margin: 40px !important;
  }

  .login-header {
    position: relative;
  }

  .v-footer--absolute {
    position: initial;
  }

  .bg-img-login {
    max-height: calc(100vh - 140px);
    height: calc(100vh - 140px);
  }
}
</style>
