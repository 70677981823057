<template>
  
  
    <v-card
      :to="{ name: 'article', params: { id: article.id } }"
      class=""
    >
    <v-responsive :aspect-ratio="1/1" v-if="article.cover">
        <v-img v-if="article.cover"
          :src="article.cover"
          class=""
          aspect-ratio="1"
          cover
        >
        <h3 class="art-title mt-3 ml-4 pl-2 mr-4 pr-2 body-1 font-weight-bold black--text">
          {{ article.header }}
        </h3>
        <p class="art-descr ml-4 pl-2 mb-2 mr-4 pr-2 body-1 black--text">
          {{ article.title }}
        </p>
      </v-img>
      </v-responsive>

      <v-responsive :aspect-ratio="1/1" v-else>
        <h3 class="art-title mt-3 ml-4 pl-2 mr-4 pr-2 body-1 font-weight-bold" :class="article.color + '--text'">
          {{ article.header }}
        </h3>
        <p class="art-descr ml-4 pl-2 mb-2 mr-4 pr-2 body-1" :class="article.color + '--text'">
          {{ article.title }}
        </p>
      </v-responsive>

      
      

    </v-card>
    

</template>

<script>
export default {
  props: {
    article: Object
  }
}
</script>

<style lang="scss">
.art-title{
  background-color: rgba(255, 255, 255, 0.75);
}
.art-descr{
  background-color: rgba(255, 255, 255, 0.75);
}
.v-card__actions {
  height: 200px;
}
</style>
