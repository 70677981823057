<template>
  <v-container class="pt-7">
    <v-row justify="space-between">
      <v-col cols="12" class="pb-0">
        <v-subheader
          class="subtitle-1 font-weight-medium grey--text pa-0"
          v-html="$t('article.last_articles')"
        ></v-subheader>
      </v-col>
    </v-row>
    <v-row v-if="!loadinglatest">
      <v-col
        cols="12"
        :sm="6"
        :md="4"
        :lg="3"
        :xl="2"
        v-for="(article, i) in latestarticles"
        :key="article.id + i"
      >
        <ArticleTeaser :article="article" />
      </v-col>
    </v-row>

    <v-row v-if="loadinglatest">
      <v-col
        cols="12"
        :sm="6"
        :md="4"
        :lg="3"
        :xl="2"
        v-for="i in 8"
        :key="'homesmallteaser' + i"
      >
        <v-skeleton-loader
          class="ma-0 pa-0 skeleton_homesmallteaser"
          type="image"
          height="86"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleTeaser from '@/components/ArticleTeaser'

import api from '@/services/api'

export default {
  data() {
    return {
      latestarticles: [],
      loadinglatest: true
    }
  },
  mounted() {
    api.getLatestArticles(this.$store.getters.getProfile.uid).then(response => {
      this.latestarticles = response.data
      this.loadinglatest = false
      
    })
  },
  components: {
    ArticleTeaser
  }
}
</script>
