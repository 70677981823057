<template>
  <v-card tile outlined class="pt-0 pb-4 test-color">
      <v-row justify="space-between" class="my-4 px-6 px-md-12 px-lg-16">
        <v-col cols="8" :sm="6" :md="6" :lg="3" class="pa-0 d-flex align-center justify-end">
          <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
              <v-text-field
                color="white"
                v-model="query"
                rounded
                append-outer-icon="mdi-magnify"
                filled
                clear-icon="mdi-close-circle"
                clearable
                type="text"
                @click:append-outer="sendMessage"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>

        </v-col>
        <v-col cols="4" :sm="6" :md="6" :lg="3" class="pa-0 d-flex align-center justify-end">

            <div class="d-flex flex-no-wrap justify-end align-center">
              <div class="d-none d-sm-block pr-5 white--text">
                <p
                  class="ma-0 text-right title font-weight-regular"
                  v-text="
                    $store.getters.getProfile.firstname +
                      ' ' +
                      $store.getters.getProfile.lastname
                  "
                ></p>
                <p class="ma-0 text-right"></p>
              </div>
              <v-avatar color="grey darken-3" size="45">
                <span class="white--text title font-weight-medium">{{
                  $store.getters.getProfile.initials
                }}</span>
              </v-avatar>
            </div>

        </v-col>
      </v-row>

      <v-img src="@/assets/img/home_login_new.jpg" cover height="400"></v-img>

      <v-row justify="space-between" class="pa-4 pt-9 pb-10 px-md-12 px-lg-16">
        <v-col cols="12" class="pt-0 pb-0 pb-sm-5">
          <h1 class="white--text pa-0">
            Nos connaissances, pour vous
          </h1>
        </v-col>
        <v-col cols="12" :sm="6" class="py-6 py-sm-0 d-flex flex-column justify-end">



          <div class="d-flex">
            <div class="d-flex align-center pr-5">
              <span class="white--text font-weight-bold display-1 bigNumber">
                {{ totalarticle }}
              </span>
              <span class="white--text pl-3 title font-weight-medium">
                {{ $t('general.card_number') }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
  </v-card>
</template>

<script>
import api from '@/services/api'
export default {
  data() {
    return {
      profile: Object,
      score: 0,
      totalarticle: 0,
      query: ''
    }
  },
  created() {
    api.getScore(this.$store.getters.getProfile.uid).then(response => {
      this.score = response.data
    })
    api.getTotalArticles().then(response => {
      this.totalarticle = response.data
    })
  },

  methods: {
    sendMessage() {
      this.$router.push('/search?query=' + this.query)
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}

.triangle {
  .corner_header {
    position: absolute;
    top: 0px;
    left: 0px;
    height:35px;
    z-index: 1;
    font-size: 20px;
    font-family: 'Lora', serif !important;
  }
}

.test-color {
  position: relative;
  background-size: 2000% 2000% !important;
  color: #fff;
  -webkit-animation: GradientAnimation 140s ease infinite;
  -moz-animation: GradientAnimation 140s ease infinite;
  animation: GradientAnimation 140s ease infinite;
  width: auto;
  margin: 0;
  border-top: 0px solid transparent !important;
  background: linear-gradient(
    270deg,
    #4191b4,
    #3c9646,
    #ff9600,
    #e61400,
    #c3c850,
    #fad200,
    #96a0be,
    #a05087
  );
}
@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
