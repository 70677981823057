<template>
  <div>
    <v-card tile outlined class="py-10" :color="article.category.color"
      ><v-container>
        <!-- <div
          class="triangle corner_header mb-0 font-weight-black white--text"
          :class="article.category.color + '_color'"
        >
          {{ article.category.num }}
        </div> -->
        <v-row justify="space-between">
          <v-col cols="12" :md="6">
            <v-list-item class="pl-0">
              <v-list-item-content>
                <v-card-title class="white--text pa-0">
                  <h1 v-html="article.category.title" class="title-header"></h1>
                </v-card-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" :md="6" v-if="article.parent">
            <v-row>
              <v-col cols="6">
                <v-card
                  v-if="article.parent"
                  class="d-inline-block"
                  :to="{ name: 'article', params: { id: article.parent.id } }"
                  width="100%"
                >
                  <v-container class="pa-2">
                    <v-row justify="space-between" align="center">
                      <v-col cols="2" align="center" class="pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-icon medium :color="article.category.color"
                              >mdi-arrow-left</v-icon
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="10" class="py-0 pl-0 pr-2">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="mb-1 pink--text font-weight-medium"
                              :class="article.category.color + '--text'"
                              v-html="$t('general.buttons.article_parent')"
                            >
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="grey--text text-truncate"
                            >
                              {{ article.parent.title }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" :md="6" v-else>
            <v-row>
              <v-col cols="6">
                <v-card
                  v-if="previous"
                  class="d-inline-block"
                  :to="{ name: 'article', params: { id: previous.id } }"
                  width="100%"
                >
                  <v-container class="pa-2">
                    <v-row justify="space-between" align="center">
                      <v-col cols="2" align="center" class="pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-icon medium :color="article.category.color"
                              >mdi-arrow-left</v-icon
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="10" class="py-0 pl-0 pr-2 ma-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="mb-1 pink--text font-weight-medium"
                              :class="article.category.color + '--text'"
                              v-html="$t('general.buttons.article_prev')"
                            >
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="grey--text text-truncate"
                            >
                              {{ previous.title }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card
                  v-if="next"
                  class="d-inline-block"
                  :to="{ name: 'article', params: { id: next.id } }"
                  width="100%"
                >
                  <v-container class="pa-2">
                    <v-row justify="space-between" align="center">
                      <v-col cols="10" class="py-0 pl-4 pr-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="mb-1 pink--text font-weight-medium"
                              :class="article.category.color + '--text'"
                              v-html="$t('general.buttons.article_next')"
                            >
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text">
                              {{ next.title }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="2" align="center" class="pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-icon medium :color="article.category.color"
                              >mdi-arrow-right</v-icon
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    article: Object,
    previous: Object,
    next: Object
  },
  return: {
    data() {}
  }
}
</script>
>
