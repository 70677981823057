var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"to":{ name: 'category', params: { id: _vm.category.id } }}},[_c('v-container',{staticClass:"pt-0 pb-4 px-0"},[_c('div',{staticClass:"number mb-0 px-4 py-2 font-weight-black white--text",class:_vm.category.color + ' darken-2'},[_vm._v(" "+_vm._s(_vm.category.num)+" ")]),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"align-end"},[_c('v-progress-linear',{staticClass:"my-6 progressbar_teaser rounded-pill",attrs:{"background-color":"transparent","color":_vm.category.color,"height":"20","value":_vm.categoriesscore[_vm.category.id]
                  ? _vm.categoriesscore[_vm.category.id].score
                  : 0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
return [_c('strong',{staticClass:"white--text pl-5"},[_vm._v(_vm._s(Math.ceil(value))),_c('span',{staticClass:"percent"},[_vm._v("%")])])]}}])}),_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.category.color +
                    '--text' +
                    ' subtitle-2' +
                    ' d-inline-block' +
                    ' text-truncate' +
                    ' py-0',attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(_vm.category.name)+" ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"medium":"","color":_vm.category.color}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }