import axios from 'axios'
import store from '@/store'
import { cacheAdapterEnhancer } from 'axios-extensions'

const baseURL = 'https://admin.formation.ciafj.ch'
//const baseURL = 'http://ciafj.localhost:8000'
const http = axios.create({
  baseURL: '/',
  // cache will be enabled by default
  //adapter: cacheAdapterEnhancer(axios.defaults.adapter)
})
const ApiCall = ({ url, formData, method }) =>
  new Promise((resolve, reject) => {
    try {
      if (store.getters.isAuthenticated) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${store.state.auth.token}`
      }
      if (method == 'get') {
        resolve(http[method](baseURL + '/' + store.getters.getLanguage + url))
      } else if (method == 'post') {
        resolve(http[method](baseURL + url, formData))
      }
    } catch (err) {
      reject(new Error(err))
    }
  })

export default ApiCall
