import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Article from '../views/Article.vue'
import Quiz from '../views/Quiz.vue'
import Category from '../views/Category.vue'
import Tag from '../views/Tag.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import Reset from '../views/Reset.vue'
import store from '../store'
import { i18n } from '../plugins/i18n.js'

Vue.use(VueRouter)

const ifAuthenticated = (from, to, next) => {
  if (store.getters.isAuthenticated === true) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/article/:id',
    name: 'article',
    component: Article,
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/quiz/:id',
    name: 'quit',
    component: Quiz,
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/category/:id',
    name: 'category',
    component: Category,
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/tag/:id',
    name: 'tag',
    component: Tag,
    beforeEnter: ifAuthenticated,
    props: true
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    beforeEnter: ifAuthenticated,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  i18n.locale = store.getters.getLanguage
  next()
})

export default router
