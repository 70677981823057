<template>
  <v-card tile class="white--text" :color="category.color">
      <v-row justify="space-between" class="pa-3" style="position:relative;">

        <v-col cols="12" :sm="1" class="pt-5 pb-5 d-flex justify-center headline" :class="category.color + ' darken-2'">
          <h3>{{ category.num }}</h3>
        </v-col>
        <v-col cols="12" :sm="11" class="pt-5 pb-12">

          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-container>
                  <v-row class="justify-end px-0">
                    <v-col cols="8" :sm="6" :md="6" :lg="3">
                      <v-text-field
                        class="ma-0 pa-0"
                        color="white"
                        v-model="query"
                        rounded
                        append-outer-icon="mdi-magnify"
                        filled
                        clear-icon="mdi-close-circle"
                        clearable
                        type="text"
                        @click:append-outer="sendMessage"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" :sm="6" :md="6" :lg="3" class="pa-0">
                      <v-list-item link :to="{ name: 'profile' }" class="pa-0">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-end white--text d-none d-sm-block title font-weight-regular"
                            v-text="
                              $store.getters.getProfile.firstname +
                                ' ' +
                                $store.getters.getProfile.lastname
                            "
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-avatar color="grey darken-2" size="45">
                            <span class="white--text title font-weight-medium">
                              {{ $store.getters.getProfile.initials }}
                            </span>
                          </v-avatar>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>



              </v-col>
              <v-col cols="12" :sm="6">
                <h1 class="pb-5 white--text">
                  {{ category.name }}
                </h1>
                <div class="d-flex">
                  <div class="d-flex align-center pr-5">
                    <span class="font-weight-bold display-1 bigNumber">
                      {{ category.nbarticles }}
                    </span>
                    <span class="white--text pl-3 title font-weight-medium">
                      {{ $tc('general.card_number', category.nbarticles) }}
                    </span>
                  </div>
                  <div class="d-flex align-center pl-5" style="border-left: 1px solid white;">
                    <span class="font-weight-bold display-1 bigNumber">
                      3
                    </span>
                    <span class="white--text pl-3 title font-weight-medium">
                      Tests
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" :sm="6" class="d-flex justify-end flex-column">
                <v-card-subtitle class="pa-0 pb-4 white--text">
                  {{ $t('general.progress') }}
                </v-card-subtitle>
                <v-card-text class="pa-0">
                  <v-progress-linear
                    class="progressbar_custom articlewhite"
                    background-color="white"
                    :color="category.color"
                    rounded
                    height="35"
                    :value="score"
                  >
                    <template v-slot="{ value }">
                      <strong class="grey--text text--darken-2 d-flex align-center"
                        >{{ Math.ceil(value)
                        }}<span class="percent">%</span></strong
                      >
                    </template>
                  </v-progress-linear>
                </v-card-text>
              </v-col>

            </v-row>
          </v-container>
        </v-col>


      </v-row>
  </v-card>
</template>

<script>
import api from '@/services/api'
export default {
  props:['category'],
  data() {
    return {
      profile: Object,
      score: 0,
      totalarticle: 0,
      query: ''
    }
  },
  created() {
    api.getScore(this.$store.getters.getProfile.uid).then(response => {
      this.score = response.data
    })
    api.getTotalArticles().then(response => {
      this.totalarticle = response.data
    })
  },

  methods: {
    sendMessage() {
      this.$router.push('/search?query=' + this.query)
    }
  }
}
</script>

<style lang="scss">

</style>
