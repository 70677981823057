<template>
    <div>
        <div v-if="!loading">
            <v-list-item
            class="my-4 px-2"
            v-for="category in categories"
            :key="category.id"
            >
                <AppNavigationCategoryActive :category="category" v-if="category.id === id" />
                <AppNavigationCategoryStd :category="category" v-else/>
                
            </v-list-item>
        </div>
        <div v-if="loading">
            <v-skeleton-loader
            class="my-2 px-2 skeleton_appnavigation"
            type="card"
            height="55"
            v-for="i in 8"
            :key="i"
            ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import AppNavigationCategoryStd from '@/components/AppNavigationCategoryStd'
    import AppNavigationCategoryActive from '@/components/AppNavigationCategoryActive'
    import api from '@/services/api'

    export default {
        props: ['id'],
        data() {
            return {
                categories: Array,
                loading: false,
                profile: this.$store.getters.getProfile
            }
        },
        mounted() {
            api.getMainCategories(this.profile.uid).then(response => {
                this.categories = response.data
            })
        },
        components: {
            AppNavigationCategoryStd,
            AppNavigationCategoryActive
        }
    }
</script>