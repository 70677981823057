<template>
  <v-layout class="pa-0" column justify-space-between  style="width: 100% Important">
    <div>
      <router-link :to="{ name: 'home' }" :key="$route.fullPath">
        <v-img
          src="@/assets/img/logo.png"
          contain
          class="mr-5 ml-4 my-5"
        ></v-img>
      </router-link>

      <v-divider></v-divider>
      <AppNavigationCategories v-if="renderComponent"/>
    </div>

    <div v-if="$store.getters.getProfile">
      <v-divider></v-divider>

        <v-list-item link :to="{ name: 'profile' }">
          <v-list-item-content>
            <v-list-item-title
              v-text="
                $store.getters.getProfile.firstname +
                  ' ' +
                  $store.getters.getProfile.lastname
              "
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-avatar color="grey darken-2" size="36">
              <span class="white--text subtitle-1">
                {{ $store.getters.getProfile.initials }}
              </span>
            </v-avatar>
          </v-list-item-icon>
        </v-list-item>

      <v-list-item link @click="logout">
        <v-list-item-content>
          <v-list-item-title v-html="$t('profil.logout')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="pt-12"></v-divider>

      <v-btn rounded href="https://latoile.ciafj.ch/caregiver/workshops" color="orange" class="ml-3 white--text">Vers la toile</v-btn>

    </div>
  </v-layout>
</template>

<script>
import AppNavigationCategories from '@/components/AppNavigationCategories'

import { AUTH_LOGOUT } from '@/store/actions/auth'
import { Trans } from '@/plugins/Translation'

import api from '@/services/api'

export default {
  props: ['id'],
  data() {
    return {
      categories: [],
      loading: true,
      renderComponent: false
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
    },
    profile: function(){
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/profile'))
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage
    }
  },
  computed: {
    currentLanguage() {
      return Trans.currentLanguage
    }
  },
  mounted() {
    if(this.$route.name != 'login'){
          this.renderComponent = true
        }
  },
  watch:{
      $route (to, from){
        if(this.$route.name != 'login'){
          this.renderComponent = true
        }
      }
  },
  components: {
    AppNavigationCategories
  }
}
</script>
<style lang="scss">
.skeleton_appnavigation {
  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 5px;
  }
  .v-skeleton-loader__image {
    height: 55px;
  }
}
</style>
